<template>
    <div id="Package" class="has_fixed_bottom_box">
        <div class="container">
            <div class="warnning_txt_box mb-3">
                <span>{{$t('package.45')}}<br></span>
            </div>
            <div class="package_box">
                <div class="mb-4">
                    <select class="form-control default" v-model="status_selected" @change="GetPackage" required>
                        <option :value="item" v-for="(item, index) in status_list" :key="index">{{StatusUI(item)}}</option>
                    </select>
                </div>
                <ul class="pl-0 ">
                    <li v-for="(item, index) in package_list" :key="index">
                        <div class="box_title">
                            <h6 class="mb-0 font-weight-bold d-flex align-items-center">
                                <i class="fas fa-coin" :style="'background-color:'+item.color"></i>
                                
                                 {{item.name}}</h6>
                            <button class="btn btn-default p-0 gray_txt" v-if="item.status == 'I'" @click="buyPackage(item.no, item.value, item.buy, item.rate, index)"><span>{{$t('package.3')}}</span><small class="far fa-chevron-right ml-1"></small></button>
                        </div>
                        <div class="box_content">
                            <div class="d-flex justify-content-between align-items-center mb-2">
                                <label>
                                    {{$t('package.20')}}
                                </label>
                                <span class="status_badge" :class="{'_01': item.status == 'H', '_02': item.status == 'I', '_03': item.status == 'Y' }">{{StatusUI(item.status)}}</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <label>{{$t('package.100')}}</label>
                                <p><span class="md_small">{{item.symbol}}</span></p>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <label>{{$t('package.101')}}</label>
                                <p><span class="md_small">{{item.value}}</span></p>
                            </div>

                            <div class="d-flex justify-content-between align-items-center">
                                <label>{{$t('package.102')}}</label>
                                <p><span class="md_small">{{item.buy}}</span></p>
                            </div>

                            <div class="d-flex justify-content-between align-items-center">
                                <label>{{$t('package.48')}}</label>
                                <p><strong>{{CheckPercent(item.buy, item.rate, item.value)}}%</strong></p>
                            </div>
                            <div class="gauge_box mb-2">
                                <div class="gauge" :style="'width :'+ CheckPercent(item.buy, item.rate, item.value) +'%; background-color:'+item.color"></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="fixed_bottom_box p-3 d-flex">
                <router-link :to="`/${$i18n.locale}/page/myinfo`" class="btn btn-block btn-primary mr-2"><small>{{$t('package.9')}}</small></router-link>
                <router-link :to="`/${$i18n.locale}/page/package-history`" class="btn btn-block btn-primary mt-0 "><small>{{$t('package.10')}}</small></router-link>
            </div>
        </div>
    </div>
</template>

<script>
const exactMath = require('exact-math');
export default {
    data(){
        return{
            title:this.$t("menu.21"),
            prevP:true,
            pageD: false,
            package_list: [],
            status_selected: '',
            status_list:['A','H','I','Y','S'],
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        this.status_selected = this.status_list[0]
        if(login){    
            this.Token_Check();
        }
    },
    methods: {
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){         
                    const login = this.$store.state.login;
                    this.status_selected = this.status_list[0]
                    if(login){    
                        this.GetSync();

                        const select = this.$route.params.select;
                        if(select !== undefined){
                            this.status_selected = this.status_list[2]
                        }
                        this.GetPackage();
                        this.checkOTP()
                    }
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },
        GetPackage(){
            const status = this.status_selected;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/staking/buy/list`,{status}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){             
                        this.package_list = res.data.list
                    }
                }
            })
        },
        checkOTP(){
            
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/common/otp`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        if(res.data.cnt ==0){
                            this.$alert(this.$t('security.17')).then(
                                ()=>{
                                    this.$router.push({name:'Security'});
                                }
                            )
                        }
                        

                    }
                }
            })
        },
        buyPack : function(idx){
            const info = this.package_list[idx]; 
            const point = info.point;
            const name = info.name;            
            
            const my_point = this.point;
            const c_point = exactMath.sub((my_point*1),(point*1));

            if(this.c_email ==true && this.c_otp ==true){
                
            }else{
                this.$alert(this.$t('package.11'))
                return false;
            }
            
            if(c_point >=0){
                this.$fire({title:this.$t('package.12'),html:`${this.$t('package.13')} :${name}</br>${this.$t('package.14')}($) :${point}`}).then(
                    ()=>{
                        this.$confirm(this.$t('package.15')).then(
                            ()=>{
                                this.purchase(idx);
                            }
                        ).catch(()=>{})
                    }
                ).catch(()=>{});
            }else{
                this.infoPp = false;
                this.$alert(this.$t('package.16'));
            }            
        },
        purchase : function(idx){
            const api = this.$store.state.baseURL;

            const info = this.package_list[idx];             

            const point = info.point;
            const per = info.per;
            const rate = '1';
            const amount = point;

            this.$http.post(`${api}/member/package/point`,{point,per,rate,amount}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('package.17')).then(
                            ()=>{
                                this.$router.push({path:'/'+this.$i18n.locale+'/page/package-history'}) 
                            }
                        )
                        
                    }
                }
            })
        },
        infoPpOn: function(item){
            this.infoPp = true;
        },
        infoPpOff: function(){
            this.infoPp = false;
        },
        buyPackage: function(idx, value, buy, rate, index){
            if(value <= (buy)){
                return this.$alert(this.$t("package.122"));
            }
            const point = this.point;
            const package_point = this.package_list[index].point;
            if(point < package_point){
                this.$alert(this.$t("package.16"));
            }
            else{
                this.$router.push('/'+this.$i18n.locale+"/page/package-payment/"+idx);
            }
        },
        GetSync(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/sync`,{})
        },
        StatusUI(i){
            if( i == 'H' ){
                return this.$t('staking.44')
            }else if( i == 'I' ){
                return this.$t('staking.40')
            }else if( i == 'Y' ){
                return this.$t('staking.45')
            }else if( i == 'D' ){
                return this.$t('staking.45')
            }else if( i == 'S' ){
                return this.$t('staking.37')
            }else if( i == 'A' ){
                return "ALL"
            }else{
                return i
            }
        },
        RecentlyNum(buy, rate){
            return exactMath.round((buy * rate), -2);
        },
        CheckPercent(buy, rate, value){
            return exactMath.round((buy/value)*100, 1);
        }
    },
    filters: {
        currency: function(value){
            var num = new Number(value);
            return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        }
    }
}
</script>

<style scoped>
    .float_box{
        position: relative;
        width: 100%;
        border-radius: 12px;
        background-color: #fff;
        /* min-height: 150px; */
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14);
    }
    .wallet{
        background: #4B67F9;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #363795, #4B67F9);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #363795, #4B67F9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        color: #fff;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-height: 100px;
    }
    .wallet p{
        font-weight: 300;
        /* font-size: 14px; */
    }
    .wallet h2{
        font-size: 2em;
        font-weight: 700;
    }
    .package_box li{        
        width: 100%;
        border-radius: 6px;
        background-color: rgb(121 128 193 / 6%);
        min-height: 150px;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14);
        margin-bottom: 1em;
    }
    
    .box_title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        font-size: 13px;
        border-bottom: 1px solid #eee;
    }

    .box_title button{
        font-size: 15px;
    }

    .box_title h6 i{
        background: #4B67F9;
        border-radius: 8px;
        color: #fff;
        padding: 7px;
        font-size: 1em;
        margin-right: 10px;
    }
    .box_content{
        padding: 1em 1em;
        box-sizing: border-box;
    }
    .box_content p{
        font-weight: 500;
        margin-bottom: 5px;
    }
    .box_content label{
        font-size: 12px;
        margin-bottom: 0;
        color: #bbb;
    }
    .box_content strong small{
        font-size: 75%;
        color: #999;
    }
    .md_small{
        font-size: 90%;
    }
    @media screen and (min-width: 768px) {
        .package_box ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .package_box ul li{
            width: 49%;
        }
    }
</style>