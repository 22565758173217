<template>
    <div id="Commision" class="has_fixed_bottom_box">
        <div class="container">
            <vue-element-loading :active="save" spinner="bar-fade-scale"/>
            <div class="py-5 icon-wrap mt-4 mb-5" style="padding-bottom: 1rem !important;">
                <i class="fal fa-file-invoice-dollar"></i>
            </div>            

            <div class="form_box px-3">                
                <div class="form-group inline">
                    <label class="mr-2">{{$t('package.101')}}</label>
                    <input type="text" class="form-control text-right" :value="info.value" disabled>
                </div>
                <div class="form-group inline">
                    <label class="mr-2">{{$t('package.106')}}</label>
                    <input type="text" class="form-control text-right" :value="recently" disabled>
                </div>
                <div class="form-group inline">
                    <label class="mr-2">{{$t('package.104')}}</label>
                    <input type="text" class="form-control text-right" :value="info.daily" disabled>
                </div> 
                <div class="form-group inline">
                    <label class="mr-2">{{$t('package.107')}}[%]</label>
                    <input type="text" class="form-control text-right" :value="info.rate_text" disabled>
                </div> 
                <div class="form-group inline">
                    <label class="mr-2">{{$t('package.108')}}[{{info.symbol}}]</label>
                    <input type="text" class="form-control text-right" :value="info.min" disabled>
                </div> 

                <div class="form-group inline">
                    <label class="mr-2">{{$t('package.44')}}</label>
                    <p class="form-control text-right">{{`1${info.symbol} = ${rate}USD`}}</p>
                </div> 
                <div class="form-group inline">
                    <label class="mr-2">{{$t('package.2')}}[{{info.symbol}}]</label>
                    <input type="text" class="form-control text-right" :value="balance" disabled>
                </div>
                <div class="form-group border-line primary">
                    <input type="text" class="form-control text-right" v-model="value" :placeholder="$t('package.111')" @change="ToAddress">
                </div>
                <div class="form-group inline">
                    <label class="mr-2">{{$t('package.110')}}</label>
                    <input type="text" class="form-control text-right" :value="Gas.fee" disabled>
                </div> 
                <div class="form-group">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="conditions"
                        name="checkbox-1"
                        >
                        <small class="conditions">{{$t('package.117')}} <a href="javascript:void(0);" @click="termsPpOn()">{{$t('package.115')}}</a>{{$t('package.116')}}</small>
                    </b-form-checkbox>
                </div>
            </div>
            
            <div class="warnning_txt_box px-3 mb-3">
                <span>{{$t('package.26')}}<br></span>
            </div>

            <div class="infoPp" v-if="termsPp == true">
                <div class="dimm" @click="termsPpOff()"></div>
                <div class="content pt-4">
                    <h4 class="mb-3">{{$t('package.118')}}</h4>
                    <small v-html="sanitizeHtml($t('package.119'))"></small>
                </div>
            </div>

            <div class="infoPp" v-if="infoPp">
                <div class="dimm" @click="infoPpOff()"></div>
                <div class="content pt-4">
                    <div class="form_box pt-2" v-if="emailArea">
                        <div class="form-group line">
                            <div class="d-flex align-items-center justify-content-between">
                                <label>{{$t('package.27')}}</label>
                            </div>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="email_code" :placeholder="$t('package.28')" :disabled="email_chk">
                                <button type="button" class="btn btn-default text-right pr-0" :disabled="email_chk" @click="EmailCodeCheck">{{$t('package.29')}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="form_box pt-2" v-if="otpArea">
                        <div class="form-group line">
                            <label>OTP {{$t('package.29')}}</label>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="otp_code" :placeholder="`OTP ${$t('package.30')}`" :disabled="otp_chk">
                                <button type="button" class="btn btn-default text-right pr-0" :disabled="otp_chk" @click="OTPCodeCheck">{{$t('package.29')}}</button>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-block btn-primary mb-2" :disabled="!auth_chk" @click="ParticipationBtn"><small>{{$t('package.31')}}</small></button>
                </div>
            </div>
        </div>
        <div class="fixed_bottom_box p-3 d-flex">
            <button class="btn btn-block btn-primary mt-0" @click="ParticipationBtn"><small>{{$t('package.32')}}</small></button>
        </div>
    </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html';
const exactMath = require('exact-math');

export default {
    data(){
        return{
            title:this.$t('package.33'),
            save: false,
            prevP: false,
            pageD: false,
            index: this.$route.params.index,
            
            net: '',
            info: {value:0, daily:0, rate:0,rate_text:'',min:0,symbol:''},
            recently: 0,
            balance: 0,
            value: '',
            rate: 0,  
            toAddr: '',
            Gas: {},
            gas_balance: 0,

            // 약관 동의
            conditions: false,

            emailArea:false,
            otpArea : false,
            email_code : '',
            otp_code :'',
            email_chk:false,
            otp_chk:false,
            auth_chk: false,   
            infoPp: false,
            termsPp: false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        if(this.$store.state.login){            
            this.PackageDetail();       
            this.MySecurityInfo();
        }
    },
    methods:{   
        sanitizeHtml : sanitizeHtml,        
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },        
        PackageDetail: function() {
            this.save = true;
            const api = this.$store.state.baseURL;
            const no =this.index;
            
            this.$http.post(`${api}/member/staking/buy/detail`,{no}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        const info = res.data.info;
                        this.info = info;
                        this.recently = exactMath.round((info.value*1)-(info.buy*1), -2);
                    }
                }
            }).finally(() => {
                this.save = false;
                this.Token_Check();
                this.CheckNet();
                this.CoinBalanceData(); 
                this.CoinRate();
            })
        },
        CheckNet() {
            const symbol = this.info.symbol;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/net/check`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.net = res.data.net;
                    }
                }
            })

        },
        CoinRate() {
            const symbol = this.info.symbol;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/rate/each`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.rate = res.data.rate.usd;
                    }
                }
            })
        },
        CoinBalanceData(){
            const symbol = this.info.symbol;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info_balance`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const balance = res.data.balance;
                        this.balance = balance;

                        if(symbol != this.net) {
                             this.gas_balance = res.data.gas_balance;
                        }
                    }
                }
            })
        },
        MySecurityInfo : function(){
            const api = this.$store.state.baseURL;            
            this.$http.post(`${api}/member/setting/my_security_info`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        if(res.data.info.buy_email =='Y'){
                            this.emailArea = true
                        }else {
                            this.emailArea = false
                            this.email_chk = true
                        }

                        if(res.data.info.buy_otp =='Y'){
                            this.otpArea = true
                        }else {
                            this.otpArea = false
                            this.otp_chk = true
                        }

                    }
                }
            })
        },     
        ToAddress: function() {
            this.save = true;
            const api = this.$store.state.baseURL;
            const symbol = this.net;
            this.$http.post(`${api}/member/staking/buy/to/address`,{symbol}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.toAddr = res.data.to_address;
                    }
                }	
            }).finally(() => {
                this.GasPrice();
            })
        },
        GasPrice() {
            const api = this.$store.state.baseURL;
            const symbol = this.info.symbol;
            const to_address = this.toAddr;
            const amount = this.value;
            this.$http.post(`${api}/member/wallet/gasPrice`,{symbol, to_address, amount}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200') {
                        this.Gas = res.data.fee;
                    }
                }	
            }).finally(() => {
                this.save = false;
                this.USDCheck();
            })
        },
        // 기존과 동일하게 비트코인, 이더리움은 가스비 제외하고 토큰은 가스비 제외안하는 함수
        USDCheck: function(){              
            const value_comparison = exactMath.sub((this.balance*1), (this.value*1));
            if(value_comparison < 0 || this.balance == 0) {
                this.value = '';
                this.Gas.fee = 0;
                return this.$alert(this.$t('transaction.17'));
            }       
            
            const symbol = this.info.symbol;
            if(symbol == this.net) {
                if(this.balance < this.Gas.fee) { 
                    this.value = '';
                    this.Gas.fee = 0;
                    return this.$alert(this.$t('transaction.44'));
                } else if(parseFloat(this.value) > this.recently){
                    const price = this.recently;
                    if(price > this.balance) this.value = this.balance - this.Gas.fee;
                    else this.value = price;
                }
                else if(parseFloat(this.value) > this.balance) {
                    this.value = exactMath.floor(exactMath.add((this.balance*1), (this.Gas.fee*1)), -2);
                }   
            }
            else {
                if(this.gas_balance == 0) {
                    this.value = '';
                    this.Gas.fee = 0;
                    return this.$alert(this.$t('transaction.44'));
                }
                else if(parseFloat(this.value) > this.recently) {
                    const price = this.recently;
                    if(price <= this.balance) {
                        this.value = price;
                    }
                }
            }

                   
        },
        // 마지막으로 한번씩 더 체크
        ParticipationBtn: function() {
            const min = this.info.min;
            const value = this.value;
            const conditions = this.conditions;
            const symbol = this.info.symbol;
            const balance = this.balance;     

            if(conditions == false){
                return this.$alert(this.$t('package.114'));
            }
                   
            const balance_comparison = exactMath.sub((balance*1),(value*1));
            if(balance_comparison < 0){
                this.$alert(this.$t('package.120'));
                return false;
            }
            const value_comparison = exactMath.sub((value*1),(min*1));
            if(value_comparison < 0){
                this.value = '';                
                this.Gas.fee = 0;
                return this.$alert(this.$t('package.113'));
            }

            if(symbol == this.net){
                if(this.balance < this.Gas.fee) return this.$alert(this.$t('transaction.44'))
            }
            else {
                const gas_check = exactMath.sub((this.gas_balance*1) , (this.Gas.fee*1));
                if(gas_check < 0){
                    return this.$alert(this.$t('transaction.44'))
                }
            }
            this.AuthCheck();
        },
        buyPack : function(){            
            if(!this.email_chk || !this.otp_chk){
                this.$alert(this.$t('package.39'))
                return false;                
            }

            this.$confirm(this.$t('package.15')).then(
            ()=>{
                this.purchase();
            }).catch(()=>{
                this.infoPpOff();
            });

        },
        purchase : function(){
            this.save = true;
            const api = this.$store.state.baseURL;

            const symbol = this.info.symbol;
            const value = this.value;
            const to = this.toAddr;
            const gas = this.Gas;
            this.$http.post(`${api}/member/wallet/send`,{symbol, value, to, gas}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.PurchaseSave();
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.45')).then(
                            ()=>{
                                this.$router.go()
                            }
                        )
                    }else if(res.data.code=='110'){
                        this.$alert(this.$t('transaction.44')).then(
                            ()=>{
                                this.$router.go()
                            }
                        )
                    }else if(res.data.code=='120'){
                        this.$alert(this.$t('transaction.43')).then(
                            ()=>{
                                this.$router.go()
                            }
                        )
                    }
                }
            }).finally(() => {
                this.save = false;
                this.Token_Check();
            })
        },
        PurchaseSave : function(){
            const no = this.index;
            const value = this.value;
            const daily = this.info.daily;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/staking/buy/save`,{no, value, daily}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('package.123')+value+this.info.symbol+this.$t('package.124')).then(
                            ()=>{
                                this.$router.push({path:'/'+this.$i18n.locale+'/page/package-history'}) 
                            }
                        )
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('package.125'));
                    }
                }
            })
        },    
        AuthCheck: function() {
            if(!this.email_chk || !this.otp_chk){
                this.infoPp = true;
                if(this.emailArea) this.EmailSecurity();
            }
            else {
                this.buyPack();
            }
        },   
        AuthClear: function() {            
            this.email_code = '';
            this.otp_code = '';
            this.email_chk=false;
            this.otp_chk=false;
            this.auth_chk = false;
            this.MySecurityInfo();
        },
        EmailSecurity : function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/common/email_security`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('package.34'));
                    }
                }
            })
        },
        EmailCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.email_code;
            
            this.$http.post(`${api}/member/common/email_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('package.35')).then(
                        ()=>{
                            this.email_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        }
                    )
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('package.36'))
                    }
                }
            })
        },
        OTPCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.otp_code;
            this.$http.post(`${api}/member/common/otp_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('package.37')).then(
                        ()=>{
                            this.otp_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        })
                    }else if(res.data.code=='100'){
                        this.$confirm(this.$t('package.38')).then(() => {
                            this.$router.push('/'+this.$i18n.locale+'/page/security');
                        }).catch(()=>{})
                    }
                }
            })
        },
        infoPpOn: function(){
            this.infoPp = true;
        },
        infoPpOff: function(){
            this.infoPp = false;
            this.AuthClear();
        },  
        termsPpOn: function(){
            this.termsPp = true;
        },
        termsPpOff: function(){
            this.termsPp = false;
        },    
    },
    filters: {
        currency: function(value){
            var num = new Number(value);
            return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        }
    }
}
</script>

<style scoped>
p{
    font-size: 14px;
    margin-bottom: 0;
}
.history{
    height: calc(100vh - 106px - 85px - 54px);
    overflow-y: auto;
}
.botC{
    background-color: #fff;
}
.detail_date{
    color: #999;
}
.txt_icon{
    display: block;
    width: 30px;
    height: 30px;
    background-color: #4B67F9;
    color: #fff;
    text-align: center;
    flex-shrink: 0;
    border-radius: 100%;
    font-size: 12px;
    line-height: 30px;
    margin-right: 10px;
}

.history_box{
    font-size: 14px;
}
.history_box p{
    margin-bottom: 0;
}
.history_box .left{
    width: 50%;
}
.history_box .right{
    width: calc(50%);
    text-align: right;
    font-weight: 500;
}
.history_box .addr{
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}
.history_box .date{
    font-size: 12px;
}

.form-group.line{
    margin-bottom: 10px;
}
.form-group.line input{
    border-bottom: 0;
    font-size: 14px;
    margin-bottom: 0;
}
/* .wallet{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} */
.wallet h4{
    text-transform: uppercase;
}
input{
    font-size: 14px;
}
.float_box{
    position: relative;
    width: 100%;
    border-radius: 6px;
    background-color: #fff;
    /* min-height: 150px; */
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14);
}
.wallet{
    background: #4B67F9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #363795, #4B67F9);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #363795, #4B67F9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}
.wallet p{
    font-weight: 300;
    /* font-size: 14px; */
}
.wallet h2{
    font-size: 2em;
    font-weight: 700;
}
.form-group.border-line.primary .form-control{
    font-size: 0.9em;
}
.form-group.border-line.primary .form-control::placeholder{
    color: #aaa;
}
.conditions a{
    text-decoration: underline !important;
    color: #4B67F9;
}
</style>