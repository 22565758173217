<template>
    <div id="PackageHistory">
        <div class="container">
            <div class="history_box">
                <ul class="pl-0">
                    <li class="d-flex align-items-center mb-4" v-for="(item, index) in history" :key="index" @click="infoPpOn(item)">
                        <span class="txt_icon" :class="{'bg-danger':item.status==='C','bg-warning':item.status==='F'}">
                            <i class="fas fa-ellipsis-h" v-if="item.status==='H'"></i>
                            <i class="fas fa-check" v-if="item.status==='Y'"></i>
                            <i class="fas fa-exclamation" v-if="item.status==='C'"></i>
                            <i class="fas fa-times" v-if="item.status==='F'"></i>
                        </span>
                        <div class="left">
                            <p class="addr">{{item.name}}</p>
                            <p class="date pale_txt">{{item.create_time}}</p>
                        </div>
                        <p class="point right">{{item.value}} {{item.symbol}}</p>
                    </li>
                </ul>
            </div>
            <div class="infoPp" v-if="infoPp">
                <div class="dimm" @click="infoPpOff()"></div>
                <div class="content">
                    <div class="form-group line">
                        <label>{{$t('package.103')}}</label>
                        <input type="text" class="form-control" :value="selected_item.value+' '+selected_item.symbol" disabled>
                    </div>
                    <div class="form-group line">
                        <label>{{$t('package.104')}}</label>
                        <input type="text" class="form-control" :value="selected_item.daily + $t('package.6')" disabled>
                    </div>
                    <div class="form-group line">
                        <label>{{$t('package.105')}}</label>
                        <input type="text" class="form-control" :value="selected_item.rate_text" disabled>
                    </div>
                    <div class="form-group line">
                        <label>{{selected_item.symbol}} {{$t('package.44')}}</label>
                        <input type="text" class="form-control" :value="selected_item.rate" disabled>
                    </div>
                    <div class="form-group line">
                        <label>{{$t('package.19')}}</label>
                        <input type="text" class="form-control" :value="selected_item.create_time" disabled>
                    </div>
                    <div class="form-group line">
                        <label>{{$t('package.20')}}</label>
                        <input type="text" class="form-control" :value="statusName(selected_item.status)" disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return{
            title:this.$t('package.10'),
            prevP: false,
            pageD: false,
            history: [],
            selected_item: null,
            infoPp: false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        if(this.$store.state.login){
            this.Token_Check();
        }
    },
    methods:{ 
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){   
                    this.GetList();
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },
		GetList(){
            const api = this.$store.state.baseURL;
            const status = '0';
			this.$http.post(`${api}/member/staking/list`,{status}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        
                        this.history = res.data.list;                        
                    }else if(res.data.code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },
        statusName(status){
            if(status == 'H') return this.$t('package.49')
            else if(status == 'Y') return this.$t('package.54')
            else if(status == 'C') return this.$t('package.55')
            else if(status == 'F') return this.$t('package.56')
        },
        infoPpOn: function(item){
            this.selected_item = item;
            this.infoPp = true;
        },
        infoPpOff: function(){
            this.infoPp = false;
        },

    },
    filters: {
        currency: function(value){
            var num = new Number(value);
            return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        },
        eth: function(value){
            var num = new Number(value);
            return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        }
    }
}
</script>

<style scoped>
    .form-group.line{
        margin-bottom: 10px;
    }
    .form-group.line input{
        border-bottom: 0;
        font-size: 14px;
        margin-bottom: 0;
    }
</style>